import React, { ReactElement } from 'react'
import styled from 'styled-components'
import {
  AsyncButton,
  ButtonStyle,
  InputField,
  InputType,
} from '@one-tree/library'
import CardContent from '../../components/page/CardContent'
import CardHeader from '../../components/page/CardHeader'
import { useOrganisation } from '../../context/OrganisationProvider'
import { capitalize } from '../../helpers/DataTransformer'
import { voucherWord } from '../../helpers/FormatHelper'
import useResponsive from '../../helpers/isResponsive'
import { StateAction } from '../../types/Aliases'
import { ISearchParams, OrganisationFormat } from '../../types/Types'
import CodeInput from '../../components/fields/codeField/CodeInput'

const UserForm = styled.form`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(3, 1fr);
  grid-row-gap: 20px;
  grid-column-gap: 30px;
  .double-section {
    grid-area: 3 / 1 / 4 / 3;
    display: inline-flex;
    align-items: flex-end;
    justify-content: space-between;
    ${(props): string => props.theme.isMobile
    && 'flex-direction: column; row-gap: 10px; align-items: flex-start;'}
  }
  ${(props): string => props.theme.isMobile && 'grid-row-gap: 0;'}
`

interface UserSearchProps {
  setVoucherId: StateAction<string>
  voucherCode: string
  setVoucherCode: StateAction<string>
  setSearchParams: StateAction<ISearchParams>
  searchGiftCard: () => void
}

function UserSearch(props: UserSearchProps): ReactElement {
  const {
    setVoucherId, voucherCode, setVoucherCode, setSearchParams, searchGiftCard,
  } = props
  const { organisation } = useOrganisation()

  const updateSearch = (key: string, param: string): void => {
    setSearchParams((prevState: ISearchParams) => ({
      ...prevState,
      [key]: param,
    }))
  }

  return (
    <>
      <CardHeader title={`Find a ${voucherWord(organisation)}`} />
      <CardContent>
        <p>
          Search on any ONE of the following to check, amend or redeem a{' '}
          {voucherWord(organisation)}.
        </p>
        <UserForm
          onSubmit={(event): void => event.preventDefault()}
          theme={useResponsive()}
        >
          <InputField
            type={InputType.Text}
            label="Purchaser's name"
            onChange={(value): void => updateSearch('purchaserName', value)}
          />
          {organisation?.format === OrganisationFormat.GiftVouchers ? (
            <InputField
              type={InputType.Text}
              label="Recipient's name"
              onChange={(value): void => updateSearch('recipientName', value)}
            />
          ) : (
            <br />
          )}
          <InputField
            type={InputType.Text}
            label="Order ID"
            onChange={(value): void => updateSearch('orderId', value.replace(/\s+/g, ''))}
          />
          <InputField
            type={InputType.Text}
            label={`${capitalize(voucherWord(organisation))} ID`}
            onChange={setVoucherId}
          />
          <div className="double-section">
            <CodeInput
              value={voucherCode}
              onChange={setVoucherCode}
              label="16 character code"
            />
            <AsyncButton
              buttonStyle={ButtonStyle.Primary}
              onClick={searchGiftCard}
              style={{ minWidth: '65px' }}
            >
              Search
            </AsyncButton>
          </div>
        </UserForm>
      </CardContent>
    </>
  )
}
export default UserSearch
