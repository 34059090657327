import {
  alertError,
  AsyncButton,
  Button,
  ButtonStyle,
  capitalize,
  enumToOptions,
  InputField,
  ISelectOption,
  Modal,
  QuestionType,
  SelectField,
  Switch,
  TextArea,
} from '@one-tree/library'
import React, { ReactElement, useState } from 'react'
import styled from 'styled-components'
import { useOrganisation } from '../../../../../context/OrganisationProvider'
import { addQuestion } from '../../../../../helpers/APIHelper'
import { PortalTicketQuestion } from '../../../../../types/Types'

const Styles = styled.div`
  display: grid;
  row-gap: 12px;
  justify-items: start;
`
const ModalContents = styled.div`
  margin-top: 20px;
  min-width: 340px;
  max-width: 560px;
  display: grid;
  row-gap: 20px;
  justify-items: start;
`
const StyledInputGroup = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`
const OptionsList = styled.div`
  display: grid;
  row-gap: 6px;
`
interface ITicketAddQuestionsProps {
  setAllQuestions: (questions: PortalTicketQuestion[]) => void
}
export default function TicketAddQuestions(
  props: ITicketAddQuestionsProps,
): ReactElement {
  const { setAllQuestions } = props
  const { organisation } = useOrganisation()
  const { id } = organisation || {}
  const [modal, setModal] = useState(false)

  const [type, setType] = useState<QuestionType>(QuestionType.Text)
  const [required, setRequired] = useState(false)
  const [title, setTitle] = useState('')

  const [options, setOptions] = useState<string[]>([])
  const hasOptions = options && options.length > 0

  const handleAdd = async (): Promise<void> => {
    if (!id) return

    if (!title) {
      alertError('Question wording is required')
      return
    }

    const res = await addQuestion({
      orgId: id,
      questions: [
        {
          title,
          type,
          required,
          options,
        },
      ],
    })

    if (res && res.length) {
      setAllQuestions(res)
      setModal(false)
      setType(QuestionType.Text)
      setRequired(false)
      setTitle('')
      setOptions([])
    } else {
      alertError('Error adding question')
    }
  }

  const handleOptions = (option: ISelectOption<number> | null): void => {
    if (!option) return

    const number = option.value

    setOptions((p) => {
      if (!number) return []

      if (number < p.length) {
        return p.slice(0, number)
      }

      if (number > p.length) {
        const extra = number - p.length
        return [...p, ...Array.from({ length: extra }, () => '')]
      }

      return p
    })
  }

  const handleOption = (value: string, whichOption: number): void => {
    setOptions((p) => p.map((option, index) => {
      const result = index === whichOption ? value : option
      return result
    }))
  }

  const selectOptions = Array.from({ length: 8 }, (_, i) => ({
    value: i + 1,
    label: `${i + 1}`,
  }))

  const getPlaceholder = (): string => {
    if (type === QuestionType.Text) {
      return 'E.g. List any allergies...'
    }
    if (type === QuestionType.Checkbox) {
      return 'E.g. Select your food choices from the list below...'
    }
    if (type === QuestionType.Radio) {
      return 'E.g. Select one of the following options...'
    }
    if (type === QuestionType.Phone) {
      return 'E.g. Provide an emergency contact number...'
    }
    if (type === QuestionType.Number) {
      return 'E.g. How many people are in your group...'
    }
    return 'E.g. List any dietary requirements...'
  }

  return (
    <Styles>
      <AsyncButton onClick={(): void => setModal(true)}>
        Add question
      </AsyncButton>
      <Modal modalOpen={modal} onModalClose={(): void => setModal(false)}>
        <h3>Add a question</h3>
        <ModalContents>
          <SelectField
            label="Question type"
            options={enumToOptions(QuestionType)}
            onChange={(option): void => {
              if (option) setType(option.value)
            }}
            value={{ value: type, label: capitalize(type) }}
          />
          <Switch
            label="Answer required"
            flipLabel={true}
            value={required}
            switchAction={setRequired}
          />
          <TextArea
            label="Question wording"
            value={title}
            onChange={setTitle}
            placeholder={getPlaceholder()}
            rows={3}
            style={{ width: '100%' }}
          />
          {(type === QuestionType.Checkbox || type === QuestionType.Radio) && (
            <>
              <SelectField<number>
                label="Number of options"
                options={selectOptions}
                value={
                  hasOptions
                    ? { value: options.length, label: options.length }
                    : undefined
                }
                onChange={handleOptions}
              />
              <OptionsList>
                {hasOptions
                  && options.map((_, index) => (
                    <InputField
                      placeholder={`option ${index + 1}`}
                      onChange={(value): void => handleOption(value, index)}
                    />
                  ))}
              </OptionsList>
            </>
          )}
          <StyledInputGroup>
            <Button
              buttonStyle={ButtonStyle.Secondary}
              onClick={(): void => setModal(false)}
            >
              Cancel
            </Button>
            <AsyncButton buttonStyle={ButtonStyle.Action} onClick={handleAdd}>
              Add
            </AsyncButton>
          </StyledInputGroup>
        </ModalContents>
      </Modal>
    </Styles>
  )
}
