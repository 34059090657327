import React, { ReactElement, useEffect, useState } from 'react'
import styled from 'styled-components'
import {
  NumberField,
  InputGroup,
  ISelectOption,
  SelectField,
  stringsToOptions,
  stringToOption,
  colors,
  Switch,
  Item,
  CurrencyCode,
  useNotFirstRender,
  IGiftVoucher,
  GiftItemType,
  GiftItemStatus,
  RichField,
} from '@one-tree/library'
import { useOrganisation } from '../../../context/OrganisationProvider'
import { get } from '../../../helpers/APIHelper'
import { publishSwitchHandler } from '../../../helpers/GiftItemHelper'
import { ICategoryResponse, Resource } from '../../../types/API'
import { IGiftItemPostBody } from '../../../types/GiftItemTypes'
import ItemExtendedDescription from './designElements/ItemExtendedDescription'
import ItemImage from './designElements/ItemImage'
import ItemName from './designElements/ItemName'
import {
  getCategoriesAsOptions,
  getGiftVoucherCategories,
} from '../../../helpers/CategoryHelper'
import { voucherWord } from '../../../helpers/FormatHelper'
import {
  categoryTest,
  descriptionTest,
  detailsTest,
  getGiftItemStatus,
  isGiftItemValid,
  typeTest,
  validityTest,
} from '../../../helpers/GiftItemValidityHelper'
import ItemPrice from './designElements/ItemPrice'
import { capitalize } from '../../../helpers/DataTransformer'
import { getOrganisationColors } from '../../../helpers/OrganisationHelper'

const CompStatus = styled.div`
  color: ${colors.red};
`
const MultiText = styled.div`
  margin: 10px 0 5px;
  max-width: 500px;
  font-size: 0.9rem;
`
const OverlaySwitch = styled(Switch)`
  margin: -15px 0;
`

interface IGiftVoucherDesignProps {
  giftVoucher: IGiftVoucher
  changeGiftVoucher: (changes: IGiftItemPostBody) => void
  isEditing: boolean
}

function GiftVoucherDesign({
  giftVoucher,
  changeGiftVoucher,
  isEditing,
}: IGiftVoucherDesignProps): ReactElement {
  const [types, setTypes] = useState<GiftItemType[]>([])
  const [categories, setCategories] = useState<ICategoryResponse[]>([])
  const { organisation } = useOrganisation()

  const [imagePreview, setImagePreview] = useState<{ [url: string]: string }>({
    imageUrl: giftVoucher.imageUrl,
  })
  const [overlayPreview, setOverlayPreview] = useState<{
    [url: string]: string
  }>({
    overlayImageUrl: giftVoucher.overlayImageUrl,
  })

  const notFirstRender = useNotFirstRender()

  const giftItemWithPreview = {
    ...giftVoucher,
    ...imagePreview,
    ...overlayPreview,
  }

  // if user publishes, but then invalidates, republish if user makes valid again
  const [forcePublish, setForcePublish] = useState(
    giftVoucher.status === GiftItemStatus.Published,
  )

  const isValid = isGiftItemValid(giftItemWithPreview, organisation)

  useEffect(() => {
    const shouldUnpublish = !isValid && giftVoucher.status === GiftItemStatus.Published
    const shouldRepublish = isValid && forcePublish
    if (shouldUnpublish) {
      changeGiftVoucher({
        status: GiftItemStatus.Unpublished,
      })
    }
    if (notFirstRender && shouldRepublish) {
      changeGiftVoucher({
        status: GiftItemStatus.Published,
      })
    }
  }, [isValid])

  useEffect(() => {
    if (organisation?.categoriesEnabled) {
      (async (): Promise<void> => {
        const res = await get<ICategoryResponse[]>({
          resource: Resource.Categories,
        })
        if (res) setCategories(res)
      })()
    }
  }, [])

  useEffect(() => {
    (async (): Promise<void> => {
      const res = await get<GiftItemType[]>({
        resource: Resource.GiftVoucherTypes,
      })
      if (res) setTypes(res.sort())
    })()
  }, [])

  const { Complimentary, Monetary } = GiftItemType

  useEffect(() => {
    // if changing to complimentary, force price to Free and unpublish
    if (notFirstRender && giftVoucher.type === Complimentary) {
      changeGiftVoucher({
        value: '0',
        status: GiftItemStatus.Unpublished,
      })
    }
  }, [giftVoucher.type])

  const handleChange = <T extends unknown>(
    value: T,
    key: keyof IGiftItemPostBody,
  ): void => {
    changeGiftVoucher({
      [key]: value,
    })
  }

  const setGiftVoucherCategories = (options: ISelectOption<number>[]): void => {
    changeGiftVoucher({
      categoryIds: options.map((option: ISelectOption<number>) => option.value),
    })
  }

  const status = getGiftItemStatus(giftItemWithPreview, organisation)

  const { type } = giftVoucher
  const showMulti = type !== Complimentary && type !== Monetary && organisation?.hasIndividuals

  const [allowMulti, setAllowMulti] = useState(giftVoucher.maxIndividuals > 1)
  const multiPersonOptions = [...Array(11)].map((_, index) => ({
    label: index + 2,
    value: index + 2,
  }))

  useEffect(() => {
    if (
      allowMulti
      && (giftVoucher.maxIndividuals === 1 || !giftVoucher.maxIndividuals)
    ) {
      handleChange(2, 'maxIndividuals')
    }
  }, [allowMulti])

  useEffect(() => {
    if (!showMulti && giftVoucher.maxIndividuals > 1) {
      handleChange(1, 'maxIndividuals')
      setAllowMulti(false)
    }
  }, [showMulti])

  const typeOptions = stringsToOptions(types)
  const categoryOptions = getCategoriesAsOptions(categories)

  const renderImageOptions = (
    <>
      <ItemImage
        label="Gift image"
        previewUrl={imagePreview.imageUrl}
        giftItem={giftVoucher}
        changeItem={changeGiftVoucher}
        changePreview={setImagePreview}
        organisation={organisation}
        isEditing={isEditing}
      />
      <OverlaySwitch
        label="Add image overlay"
        flipLabel={true}
        value={giftVoucher.showOverlay}
        switchAction={(isOn): void => handleChange(isOn, 'showOverlay')}
      />
      {giftVoucher.showOverlay && (
        <ItemImage
          previewUrl={overlayPreview.overlayImageUrl}
          giftItem={giftVoucher}
          changeItem={changeGiftVoucher}
          changePreview={setOverlayPreview}
          organisation={organisation}
          isEditing={isEditing}
          isOverlay={true}
        />
      )}
    </>
  )

  return (
    <>
      <section className="gift-settings gift-settings-styles">
        <h3>Gift settings</h3>
        <InputGroup>
          <SelectField
            placeholder={
              typeOptions.length ? 'Select type...' : 'No types found'
            }
            options={typeOptions}
            onChange={(option): void => {
              if (option) {
                handleChange(option.value, 'type')
              }
            }}
            value={stringToOption(giftVoucher?.type)}
            disabled={!types.length}
            isError={
              isEditing
              && Boolean(typeTest({ giftItem: giftVoucher, organisation }))
            }
          />
          {organisation?.categoriesEnabled && (
            <SelectField
              placeholder={
                categoryOptions.length
                  ? 'Select categories...'
                  : 'No categories found'
              }
              options={categoryOptions}
              onChange={(options): void => {
                if (options) setGiftVoucherCategories(options)
              }}
              isMulti={true}
              isLong={true}
              value={getGiftVoucherCategories(giftVoucher, categories)}
              isError={
                isEditing
                && Boolean(categoryTest({ giftItem: giftVoucher, organisation }))
              }
            />
          )}
          {giftVoucher.type !== Complimentary && (
            <Switch
              label={capitalize(status)}
              disabled={!isValid}
              flipLabel={true}
              value={giftVoucher?.status === GiftItemStatus.Published}
              switchAction={(isOn): void => {
                publishSwitchHandler(isOn, changeGiftVoucher, setForcePublish)
              }}
            />
          )}
        </InputGroup>
        {giftVoucher.type !== Complimentary && organisation ? (
          <ItemPrice
            label="Price"
            giftItem={giftVoucher}
            changeItem={changeGiftVoucher}
            organisation={organisation}
            isEditing={isEditing}
          />
        ) : (
          <CompStatus>
            {status === GiftItemStatus.Unpublished || capitalize(status)}
          </CompStatus>
        )}
        {showMulti && (
          <div>
            <InputGroup style={{ minHeight: '50px' }}>
              <Switch
                label="Allow multiple guests"
                value={allowMulti}
                switchAction={(isOn): void => {
                  setAllowMulti(isOn)
                  if (!isOn && giftVoucher.maxIndividuals !== 1) {
                    handleChange(1, 'maxIndividuals')
                  }
                }}
                labelOffset={true}
              />
              {allowMulti ? (
                <SelectField
                  label="Max number of people"
                  options={multiPersonOptions}
                  value={{
                    label: giftVoucher.maxIndividuals,
                    value: giftVoucher.maxIndividuals,
                  }}
                  onChange={(option): void => {
                    if (option) handleChange(option.value, 'maxIndividuals')
                  }}
                />
              ) : null}
            </InputGroup>
            <MultiText>
              Turn on multiple guests to allow customers to choose their own
              number of guests. The price will be multiplied by the number
              chosen, so please enter the price for one person.
            </MultiText>
          </div>
        )}
      </section>
      <section className="gift-design">
        <h3>Gift design</h3>
        <ItemName
          label="Gift name"
          value={giftVoucher.name}
          giftItem={giftVoucher}
          changeItem={changeGiftVoucher}
          organisation={organisation}
          isEditing={isEditing}
        />
        <RichField
          label="Summary"
          maxChars={100}
          maxLines={4}
          placeholder={`Include the key highlights of the ${voucherWord(
            organisation,
          )}. This information appears on the web and the ${voucherWord(
            organisation,
          )}.`}
          value={giftVoucher.description}
          onChange={(content): void => handleChange(content, 'description')}
          isError={
            isEditing
            && Boolean(descriptionTest({ giftItem: giftVoucher, organisation }))
          }
          subtitle={
            (isEditing
              && descriptionTest({ giftItem: giftVoucher, organisation }))
            || undefined
          }
        />
        {giftVoucher.type !== Complimentary && renderImageOptions}
      </section>
      <section className="preview-design">
        <h3>Web preview (summary text)</h3>
        <Item
          giftItem={giftItemWithPreview}
          currency={organisation?.currencyCode || CurrencyCode.GBP}
          organisationColors={getOrganisationColors(organisation)}
        />
      </section>
      <section className="gift-description">
        <h3>Gift description</h3>
        <NumberField
          label="Validity period (in months)"
          placeholder="how many months?"
          onChange={(number): void => handleChange(number, 'validity')}
          value={giftVoucher.validity}
          className="input-short"
          isError={
            isEditing
            && Boolean(validityTest({ giftItem: giftVoucher, organisation }))
          }
          subtitle={
            (isEditing
              && validityTest({ giftItem: giftVoucher, organisation }))
            || undefined
          }
        />
        <RichField
          label="Description"
          maxChars={200}
          maxLines={3}
          placeholder="Expand on the summary"
          value={giftVoucher.details}
          onChange={(content): void => handleChange(content, 'details')}
          isError={
            isEditing
            && Boolean(detailsTest({ giftItem: giftVoucher, organisation }))
          }
          subtitle={
            (isEditing
              && detailsTest({ giftItem: giftVoucher, organisation }))
            || undefined
          }
        />
        <ItemExtendedDescription
          value={giftVoucher.terms}
          giftItem={giftVoucher}
          changeItem={changeGiftVoucher}
          label="Extended description (optional)"
          isEditing={isEditing}
          organisation={organisation}
          maxChars={300}
          maxLines={3}
          placeholder="Add any further information"
        />
      </section>
      <section className="preview-description">
        <h3>Web preview (details)</h3>
        <Item
          giftItem={giftItemWithPreview}
          currency={organisation?.currencyCode || CurrencyCode.GBP}
          setDetails={true}
          organisationColors={getOrganisationColors(organisation)}
        />
      </section>
    </>
  )
}
export default GiftVoucherDesign
