import React, { ReactElement, ReactNode } from 'react'
import { colors, Page as PageComponent } from '@one-tree/library'
import styled from 'styled-components'
import { useOrganisation } from '../context/OrganisationProvider'
import OrganisationSwitcher from '../components/header/OrganisationSwitcher'
import SideMenu from './SideMenu'
import { ReactComponent as Logo } from '../assets/logo-header.svg'
import Breadcrumbs from '../components/page/Breadcrumbs'

const StyledLogo = styled(Logo)`
  width: 165px;
  height: auto;
  fill: ${colors.white};
`
export interface IPageProps {
  children: ReactNode
  warnBeforeExit?: boolean
}
function Page(props: IPageProps): ReactElement | null {
  const { children, warnBeforeExit } = props
  const { organisation, organisationUser, setOrganisationLoading } = useOrganisation()

  return organisation && organisationUser ? (
    <PageComponent
      warnBeforeExit={warnBeforeExit}
      setLoading={setOrganisationLoading}
      primaryColor={colors.lightBlue}
      secondaryColor={colors.blue}
      switcher={<OrganisationSwitcher />}
      user={organisationUser}
      menuContent={<SideMenu />}
      logo={<StyledLogo />}
      breadcrumbs={<Breadcrumbs />}
      warnings={organisation.outstandingFields}
    >
      {children}
    </PageComponent>
  ) : null
}
export default Page
