import React, { ReactElement } from 'react'
import styled from 'styled-components'
import { AsyncButton, ButtonStyle, InputGroup } from '@one-tree/library'
import CardContent from '../../components/page/CardContent'
import CardHeader from '../../components/page/CardHeader'
import { useOrganisation } from '../../context/OrganisationProvider'
import { voucherWord } from '../../helpers/FormatHelper'
import { StateAction } from '../../types/Aliases'
import useResponsive from '../../helpers/isResponsive'
import CodeInput from '../../components/fields/codeField/CodeInput'

const StyledInputGroup = styled(InputGroup)`
  ${(props): string => props.theme.isMobile
    && 'flex-direction: column; row-gap: 10px; align-items: flex-start !important;'}
`

interface RedeemerSearchProps {
  voucherCode: string
  setVoucherCode: StateAction<string>
  searchGiftCard: () => void
}

function RedeemerSearch(props: RedeemerSearchProps): ReactElement {
  const { voucherCode, setVoucherCode, searchGiftCard } = props
  const { organisation } = useOrganisation()

  return (
    <>
      <CardHeader title={`Redeem a ${voucherWord(organisation)}`} />
      <CardContent>
        <p>
          Enter the unique 16 character code. The code includes the letters A-F
          and numbers 0-9. It is not case-sensitive.
        </p>
        <form onSubmit={(event): void => event.preventDefault()}>
          <StyledInputGroup theme={useResponsive()}>
            <CodeInput
              value={voucherCode}
              onChange={setVoucherCode}
            />
            <AsyncButton
              buttonStyle={ButtonStyle.Primary}
              onClick={searchGiftCard}
            >
              Check validity
            </AsyncButton>
          </StyledInputGroup>
        </form>
      </CardContent>
    </>
  )
}
export default RedeemerSearch
