import React, { CSSProperties, ReactElement, ReactNode } from 'react'

export interface IResultTableRowProps {
  title: string
  value: ReactNode
  className?: string
  style?: CSSProperties
}
function ResultTableRow({
  title,
  value,
  className,
  style,
}: IResultTableRowProps): ReactElement {
  return (
    <tr className={className}>
      <th>{title}</th>
      <td style={style}>{value}</td>
    </tr>
  )
}
export default ResultTableRow
