import styled from 'styled-components'
import React, { ReactElement, useEffect, useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import {
  colors, hasRole, IGiftCardResponse, Role,
} from '@one-tree/library'
import Card, { CardSize } from '../../components/page/Card'
import { get } from '../../helpers/APIHelper'
import Page from '../../hoc/Page'
import { Resource } from '../../types/API'
import { RoutePath } from '../../types/Routes'
import { useOrganisation } from '../../context/OrganisationProvider'
import GiftCardActions from './GiftCardActions'
import ResultTable from './resultTable/ResultTable'
import UserResultTable from './UserResultTable'
import GiftCardRedeem from './GiftCardRedeem'

const StyledCard = styled(Card)`
  color: ${colors.black};
  h2 {
    margin: 0;
  }
  & :first-letter {
    text-transform: uppercase;
  }
  & .gift-card-email td:first-letter {
    text-transform: lowercase;
  }
`

const ActionsContainer = styled.div`
  position: absolute;
  top: 25px;
  right: 25px;
  button {
    margin-left: 10px;
  }
`

interface IGiftCardResultParams {
  id?: string
}

function GiftCardResult(): ReactElement {
  const { id }: IGiftCardResultParams = useParams()
  const [giftCard, setGiftCard] = useState<IGiftCardResponse | false>(false)

  const history = useHistory()

  if (!id) history.push(RoutePath.Home)

  const { organisationUser } = useOrganisation()

  // if card has just been Redeemed, though the card is now invalid, it should read 'Redeemed'
  const [justRedeemed, setJustRedeemed] = useState<boolean>(false)

  const fetchGiftCard = async (): Promise<void> => {
    const res = id && (await get<IGiftCardResponse>(
      { resource: Resource.GiftCards, urlPath: [id] },
    ))
    if (res) {
      setGiftCard(res)
    } else {
      history.push(RoutePath.Home)
    }
  }

  useEffect(() => {
    fetchGiftCard()
  }, [justRedeemed])

  const { role } = organisationUser || {}

  return (
    <Page>
      {giftCard && (
        <StyledCard cardSize={CardSize.Medium}>
          <ActionsContainer>
            <GiftCardActions giftCard={giftCard} setGiftCard={setGiftCard} />
            <GiftCardRedeem
              giftCard={giftCard}
              setJustRedeemed={setJustRedeemed}
            />
          </ActionsContainer>
          <ResultTable giftCard={giftCard} justRedeemed={justRedeemed}>
            {role && hasRole(role, Role.OrganisationUser) && (
              <UserResultTable
                giftCard={giftCard}
                setGiftCard={setGiftCard}
                justRedeemed={justRedeemed}
                fetchGiftCard={fetchGiftCard}
              />
            )}
          </ResultTable>
        </StyledCard>
      )}
    </Page>
  )
}
export default GiftCardResult
